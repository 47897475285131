// exports.onClientEntry = () => {
//   // Check if the page was reloaded
//   if (window.performance && performance.navigation.type === 1) {
//     window.scrollTo(0, 0);
//   }
// };

import "./src/styles/global.css";
// gatsby-browser.js
// gatsby-browser.js
// export const onRouteUpdate = ({ location, prevLocation }) => {
//   if (location !== prevLocation) {
//     window.scrollTo(0, 0);
//   }
// };
// gatsby-browser.js

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   // Check if there is a previous location (i.e., navigation occurred)
//   if (prevLocation && window.scrollY !== 0) {
//     // Save the scroll position of the previous page before navigating away
//     sessionStorage.setItem(prevLocation.pathname, window.scrollY);
//   }

//   // On page load, check if there is a stored scroll position for the current page
//   const savedScrollPosition = sessionStorage.getItem(location.pathname);

//   if (savedScrollPosition) {
//     window.scrollTo(0, parseInt(savedScrollPosition, 10));
//   } else {
//     window.scrollTo(0, 0);
//   }
// };

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const isNavLinkClick = sessionStorage.getItem("isNavLinkClick");

  if (isNavLinkClick === "true") {
    window.scrollTo(0, 0);
  } else {
    const currentPosition = getSavedScrollPosition(location);
    if (!currentPosition) {
      window.scrollTo(0, 0);
      return false;
    }
    window.scrollTo(...(currentPosition || [0, 0]));
  }
  return false;
};

// Save the scroll position of the page before leaving

export const onClientEntry = () => {
  if (typeof window !== "undefined" && "scrollRestoration" in window.history) {
    // Enable the browser to handle back/forward navigation scroll position
    window.history.scrollRestoration = "auto";
  }
};
